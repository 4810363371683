var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    attrs: {
      "type": "border-info card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info",
    attrs: {
      "title": "",
      "class-header": "card-header bg-light-info",
      "is-visible": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.history.title')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('BTabs', [_c('BTab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.$t('invoice.history.transmission')))])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    staticClass: "mb-1 rounded",
    staticStyle: {
      "max-height": "50vh"
    },
    attrs: {
      "bordered": "",
      "responsive": "",
      "sticky-header": true,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "items": _vm.history,
      "fields": _vm.tableColumnsTransmission,
      "thead-class": "text-dark font-weight-bolder text-nowrap"
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsTransmission, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column.key,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.history.columns.".concat(column.key))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.ftvanNotificationName) + " ")];
      }
    }, {
      key: "cell(time)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.ftvanNotificationCreatedDate).dateTime) + " ")];
      }
    }, {
      key: "cell(taxAuthorityCode)",
      fn: function fn(_ref3) {
        var _item$content;
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$content = item.content) === null || _item$content === void 0 ? void 0 : _item$content.taxAuthorityCode) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.ftvanNotificationName) + " ")];
      }
    }, {
      key: "cell(content)",
      fn: function fn(_ref5) {
        var _item$content2;
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$content2 = item.content) === null || _item$content2 === void 0 ? void 0 : _item$content2.dataReceiptTransactionCode) + " ")];
      }
    }], null, true)
  })], 1), _c('BTab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.$t('invoice.history.actionUpdate')))])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    staticClass: "mb-1 rounded",
    staticStyle: {
      "max-height": "50vh"
    },
    attrs: {
      "bordered": "",
      "responsive": "",
      "sticky-header": true,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "items": _vm.auditLogs,
      "fields": _vm.tableColumnsAuditLogs,
      "thead-class": "text-dark font-weight-bolder text-nowrap"
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsAuditLogs, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column.key,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.history.columns.".concat(column.key))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.$te("invoice.history.action.".concat(item.action)) ? _vm.$t("invoice.history.action.".concat(item.action)) : item.action) + " ")];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).dateTime) + " ")];
      }
    }, {
      key: "cell(modifiedBy)",
      fn: function fn(_ref8) {
        var _item$modifiedBy;
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$modifiedBy = item.modifiedBy) === null || _item$modifiedBy === void 0 ? void 0 : _item$modifiedBy.username) + " ")];
      }
    }, {
      key: "cell(newValue)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return _vm._l(_vm.resolveValue(item), function (value, key) {
          return _c('div', {
            key: key
          }, [key === 'invoiceItems' ? _c('div', [_c('div', {
            staticClass: "font-italic"
          }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.detail.invoiceContent')) + " ")]), _vm._l(value, function (ticket) {
            return _c('div', {
              key: ticket === null || ticket === void 0 ? void 0 : ticket.id
            }, [(ticket === null || ticket === void 0 ? void 0 : ticket.itemType) !== 'NOTE' ? _c('span', [_c('span', {
              staticClass: "font-weight-bold"
            }, [_vm._v(" " + _vm._s(ticket === null || ticket === void 0 ? void 0 : ticket.name) + " " + _vm._s(ticket === null || ticket === void 0 ? void 0 : ticket.ticketNumber) + " ")]), _c('span', {
              staticClass: "font-italic"
            }, [_vm._v(" " + _vm._s(_vm.formatCurrency(ticket === null || ticket === void 0 ? void 0 : ticket.unitPrice)) + " x " + _vm._s(ticket === null || ticket === void 0 ? void 0 : ticket.quantity) + " " + _vm._s(ticket === null || ticket === void 0 ? void 0 : ticket.unit) + " + " + _vm._s(_vm.TAX_TYPES_OBJECT[ticket === null || ticket === void 0 ? void 0 : ticket.valueAddedTax]) + "% = " + _vm._s(_vm.formatCurrency((ticket === null || ticket === void 0 ? void 0 : ticket.unitPrice) * (ticket === null || ticket === void 0 ? void 0 : ticket.quantity) * (100 + _vm.TAX_TYPES_OBJECT[ticket === null || ticket === void 0 ? void 0 : ticket.valueAddedTax]) / 100)) + " ")])]) : _vm._e()]);
          })], 2) : _c('div', [_vm._v(" " + _vm._s(_vm.$te("invoice.create.info.".concat(key)) ? _vm.$t("invoice.create.info.".concat(key)) : key) + ": " + _vm._s(value) + " ")])]);
        });
      }
    }], null, true)
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
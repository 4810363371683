<template lang="">
  <AppCollapse type="border-info card">
    <AppCollapseItem
      title
      class-header="card-header bg-light-info"
      class="border-info"
      is-visible
    >
      <template #header>
        <span class="text-dark font-weight-bolder">
          {{ $t('invoice.history.title') }}
        </span>
      </template>
      <BTabs>
        <BTab>
          <template #title>
            <span>{{ $t('invoice.history.transmission') }}</span>
          </template>
          <b-table
            bordered
            style="max-height: 50vh"
            responsive
            :sticky-header="true"
            show-empty
            :empty-text="$t('noMatchingResult')"
            no-border-collapse
            small
            :items="history"
            :fields="tableColumnsTransmission"
            class="mb-1 rounded"
            thead-class="text-dark font-weight-bolder text-nowrap"
          >
            <template
              v-for="column in tableColumnsTransmission"
              #[`head(${column.key})`]
            >
              <span
                :key="column.key"
                class="text-dark"
              >
                {{ $t(`invoice.history.columns.${column.key}`) }}
              </span>
            </template>

            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(name)="{ item }">
              {{ item.ftvanNotificationName }}
            </template>

            <template #cell(time)="{ item }">
              {{ convertISODateTime(item.ftvanNotificationCreatedDate).dateTime }}
            </template>

            <template #cell(taxAuthorityCode)="{ item }">
              {{ item?.content?.taxAuthorityCode }}
            </template>

            <template #cell(status)="{ item }">
              {{ item?.ftvanNotificationName }}
            </template>

            <template #cell(content)="{ item }">
              {{ item?.content?.dataReceiptTransactionCode }}
            </template>
          </b-table>
        </BTab>

        <BTab>
          <template #title>
            <span>{{ $t('invoice.history.actionUpdate') }}</span>
          </template>
          <b-table
            bordered
            style="max-height: 50vh"
            responsive
            :sticky-header="true"
            show-empty
            :empty-text="$t('noMatchingResult')"
            no-border-collapse
            small
            :items="auditLogs"
            :fields="tableColumnsAuditLogs"
            class="mb-1 rounded"
            thead-class="text-dark font-weight-bolder text-nowrap"
          >
            <template
              v-for="column in tableColumnsAuditLogs"
              #[`head(${column.key})`]
            >
              <span
                :key="column.key"
                class="text-dark"
              >
                {{ $t(`invoice.history.columns.${column.key}`) }}
              </span>
            </template>

            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(action)="{ item }">
              {{ $te(`invoice.history.action.${item.action}`)
                ? $t(`invoice.history.action.${item.action}`)
                : item.action }}
            </template>

            <template #cell(createdAt)="{ item }">
              {{ convertISODateTime(item.createdAt).dateTime }}
            </template>

            <template #cell(modifiedBy)="{ item }">
              {{ item?.modifiedBy?.username }}
            </template>

            <template #cell(newValue)="{ item }">
              <div
                v-for="(value, key) in resolveValue(item)"
                :key="key"
              >
                <div v-if="key === 'invoiceItems'">
                  <div class="font-italic">
                    {{ $t('invoice.create.detail.invoiceContent') }}
                  </div>
                  <div
                    v-for="ticket in value"
                    :key="ticket?.id"
                  >
                    <span v-if="ticket?.itemType !== 'NOTE'">
                      <span class="font-weight-bold">
                        {{ ticket?.name }}
                        {{ ticket?.ticketNumber }}
                      </span>
                      <span class="font-italic">
                        {{ formatCurrency(ticket?.unitPrice) }} x
                        {{ ticket?.quantity }}
                        {{ ticket?.unit }}
                        + {{ TAX_TYPES_OBJECT[ticket?.valueAddedTax] }}%
                        = {{ formatCurrency(((ticket?.unitPrice*ticket?.quantity) * (100 + TAX_TYPES_OBJECT[ticket?.valueAddedTax])) / 100) }}
                      </span>
                    </span>
                  </div>
                </div>
                <div v-else>
                  {{ $te(`invoice.create.info.${key}`) ? $t(`invoice.create.info.${key}`) : key }}: {{ value }}
                </div>
              </div>
            </template>
          </b-table>
        </BTab>
      </BTabs>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>
import { ref } from '@vue/composition-api'
import { BTab, BTable, BTabs } from 'bootstrap-vue'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import { TAX_TYPES_OBJECT } from '@/constants/invoice'

export default {
  components: {
    BTable,
    BTabs,
    BTab,
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  props: {
    history: {
      type: Array,
      default: () => [],
    },
    auditLogs: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const tableColumnsTransmission = ref([
      { label: 'no', key: 'no' },
      { label: 'name', key: 'name' },
      { label: 'time', key: 'time' },
      { label: 'taxAuthorityCode', key: 'taxAuthorityCode' },
      { label: 'status', key: 'status' },
      { label: 'content', key: 'content' },
    ])

    const tableColumnsAuditLogs = ref([
      { label: 'no', key: 'no' },
      { label: 'action', key: 'action' },
      { label: 'newValue', key: 'newValue' },
      { label: 'createdAt', key: 'createdAt' },
      { label: 'modifiedBy', key: 'modifiedBy' },
    ])
    function resolveValue(item) {
      const newValue = JSON.parse(item?.newValue || null)
      const oldValue = JSON.parse(item?.oldValue || null)
      if (item.action === 'UPDATE') {
        return newValue
      }
      if (item.action === 'SIGN') {
        return oldValue
      }
      return null
    }
    return {
      tableColumnsTransmission,
      convertISODateTime,
      tableColumnsAuditLogs,
      resolveValue,
      formatCurrency,
      TAX_TYPES_OBJECT,
    }
  },
}
</script>
<style lang="">

</style>
